import * as React from "react"
import Layout from "../components/layout"
import ReactPlayer from "react-player"

const SermonPage = props => {
  const { pageContext } = props
  return (
    <Layout>
      <div class=" p-10">
        <div class="flex  bg-white shadow-md rounded-lg overflow-hidden">
          <div class="flex  w-full">
            <div class="w-full p-10">
              <div class="border-b border-gray-100"></div>
              <div>
                <ReactPlayer
                  url={pageContext.url}
                  controls
                  height={"3rem"}
                  width={"100%"}
                />
              </div>
              <div class="text-gray-600 font-semibold text-lg mb-2 mx-3 px-2">
                {pageContext.book} {pageContext.chapter} [SERMON AUTOMATION TEST
                - TO BE UPDATED]
              </div>
              <div class="text-gray-500 font-thin text-sm mb-6 mx-3 px-2">
                {pageContext.author}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SermonPage
